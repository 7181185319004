.image-block img { 
    width: 100%;
}

@media (min-width: 768px) {
    .image-block {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .image-block img { 
        width: 25%;
    }
}