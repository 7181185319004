.reasoning-container {
    background-color: var(--bg-light-gray); 
    padding-top: 60px;
}

.reasoning .heading.uppercase { 
    margin-bottom: 60px;
}

.reasoning .reasoning__grid-item {
    margin-bottom: 60px;
}

.reasoning h3 {
  font-size: 1.3em;
}

.reasoning h2 {
  padding-bottom: 30px;
}


@media (min-width: 768px) {
    /* reasoning GRID FLEX */
    .reasoning .reasoning__grid {
        display: flex;    
        flex-wrap: wrap;  
        justify-content: space-between;  
    }
    .reasoning .reasoning__grid .reasoning__grid-item {
        width: 48%;
    }
    .reasoning .reasoning__grid .reasoning__grid-item:first-child {
        margin-right: 2%;
    }
    .reasoning .reasoning__grid .reasoning__grid-item p {
        max-width: 350px;
    }

    /* INNER reasoning GRID */
    .reasoning .reasoning__grid .reasoning__grid-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .reasoning .reasoning__grid .reasoning__grid-item-inner.image-container {
        margin-right: 30px;
    }
}

@media (min-width: 1024px) {
    .reasoning .reasoning__grid .reasoning__grid-item-inner.image-container {
        width: calc(40% - 30px);
    }
    .reasoning .reasoning__grid .reasoning__grid-item-inner.image-container img {
        width: 100%;
    }
    .reasoning .reasoning__grid .reasoning__grid-item-inner {
        width: 60%;
    }
}

@media (min-width: 1440px) {
    .reasoning .reasoning__grid .reasoning__grid-item-inner.image-container {
        width: calc(25% - 30px);
    }
    .reasoning .reasoning__grid .reasoning__grid-item-inner {
        width: 75%;
    }
}