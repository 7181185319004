/* INTRO */
.intro { 
    display: flex;
    flex-direction: column;
}

.intro img {
    width: 100%;
    height: auto;
}

.intro .right {
    margin: 60px 0;
}

.intro .left .view-courses-button {
    margin-top: 60px;
}

.intro .intro__cta-button {
    margin-top: 50px;
}

.intro .btn-outline { 
    color: var(--text-black);
}

@media (min-width: 768px) {
    /* INTRO SECTION */
    .intro { 
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .intro .left { 
        max-width: 450px;
    }
    .intro .right { 
        margin-left: 60px;
    }
}

@media (min-width: 1024px) {
    .intro .right { 
        margin-left: 150px;
    }
}