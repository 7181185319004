#home section {
    margin-top: 80px;
}

#home section:first-child {
    margin-top: 30px;
}

/* COURSES */
#home .course__type {
    margin-bottom: 60px;
    margin-top: 50px;
}

#home .course__type .course__subType {
    margin-top: 20px;
}

.extra { 
    margin-top: 10px;
    display: block;
    font-size: 0.88em;
    font-weight: 600;
}

/* ABOUT SECTION */
#home #about {
    padding: 40px 0 60px 0;
    background-color: var(--bg-light-gray);
}

@media (min-width: 768px) {
    /* COURSES INNER */
    #home #courses .course__type .course__type-inner {
        display: flex;
        flex-wrap: wrap;
    }
    #home #courses .course__type .course__type-inner .course__subType {
        width: 48%;
    }
    #home #courses .course__type .course__type-inner .course__subType:first-child {
        margin-right: 2%
    }
    #home #courses .course__type .course__type-inner .course__subType p {
        max-width: 450px;
    }
}
